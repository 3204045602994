import React, { useState, useRef } from 'react';
import { useDrag } from 'react-use-gesture';
import { useSpring, animated } from 'react-spring';
import './Utils.css'; // Custom CSS for styling

interface CircularSliderProps {
    onChange: (newAngele: number) => void;
    value: number
    CircleSliderClass: string
}

const CircularSlider: React.FC<CircularSliderProps> = ({ onChange, value, CircleSliderClass }) => {
    const [angle, setAngle] = useState(value);
    const dotRef = useRef<HTMLDivElement>(null);

    const bind = useDrag(({ xy: [x, y], event }) => {
        if (dotRef.current != null) {
            const rect = dotRef.current.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const deltaX = x - centerX;
            const deltaY = y - centerY;
            let newAngle = Math.round(Math.atan2(deltaY, deltaX) * (180 / Math.PI));
            if (newAngle < 0) newAngle += 360;
            setAngle(newAngle);
            onChange(newAngle);
        }
    });

    const { x, y } = useSpring({
        x: 15 + 15 * Math.cos((angle - 90) * (Math.PI / 180)),
        y: 15 + 15 * Math.sin((angle - 90) * (Math.PI / 180))
    });

    return (
        <div className={`circular-slider ${CircleSliderClass}`}>
            <div className={`circle `}>
                <animated.div
                    {...bind()}
                    ref={dotRef}
                    className={`dot `}
                    style={{ transform: x.to((x) => `translate(${x}px, ${y.get()}px)`) }}
                />
            </div>
            <input
                type="text"
                value={`${Math.round(angle)}°`}
                readOnly
                className="angle-display"
            />
        </div>
    );
};

export default CircularSlider;
