import React, { useState } from 'react';
import prettier from 'prettier/standalone';
import parseHtml from 'prettier/plugins/html'
import { Helmet } from 'react-helmet-async';
import { GoogleAddScript } from '../Constent/Uiconstent';

const HtmlPrettifier: React.FC = () => {
  const [inputHtml, setInputHtml] = useState('');
  const [formattedHtml, setFormattedHtml] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputHtml(e.target.value);
  };

  const prettifyHtml = async () => {
    try {
      const prettyHtml = await prettier.format(inputHtml, {
        parser: 'html',
        plugins: [parseHtml]
      });
      setFormattedHtml(prettyHtml);
      setError(null); // Clear any previous errors
    } catch (err) {
      setError('Error formatting HTML. Please check your input.');
      console.log(err);
      setFormattedHtml('');
    }
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen justify-center">
      <Helmet>
        <title>HTML Prettifier - Coder Utils</title>
        <meta name="description" content="Html Prettifer for prettidy your HTML,
                                          Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
        {/* Add meta tags, links, etc. here if needed */}
      </Helmet>
      <div className="w-full md:w-2/3 md:mx-4 flex-3 flex-col flex items-center relative">
        <div className="p-4 w-full">
          <h1 className="text-2xl font-bold mb-4">HTML Prettifier</h1>
          <textarea
            className="w-full min-h-72 p-2 border rounded mb-4 resize-none"
            value={inputHtml}
            onChange={handleInputChange}
            placeholder="Enter your HTML here"
          ></textarea>
          <button
            className="bg-blue-500 text-white py-2 my-2 px-4 rounded"
            onClick={prettifyHtml}
          >
            Prettify HTML
          </button>
          {error && <p className="text-red-500 mt-4">{error}</p>}
          <div className='h-10 w-full rounded-t bg-slate-600 flex justify-center items-center text-center text-white hover:bg-slate-400  hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`${formattedHtml}`) }}>
            <p>Copy to Clipboard</p>
          </div>
          <div className='w-full flex flex-col bd-black bg-slate-900 min-h-72'>
            <div className='text-white bg-slate-800 p-2 w-16'><h3>Code</h3></div>
            <code className="text-white bg-slate-800 p-2 block w-full min-h-52"><pre className='text-white bg-slate-800'>{formattedHtml}</pre></code>
          </div>
        </div></div></div>
  );
};

export default HtmlPrettifier;
