import React, { useState, useRef, useEffect } from 'react';

interface CollapsiblePanelProps {
  title: string;
  children: React.ReactNode;
}

const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef<HTMLDivElement>(null);

  const togglePanel = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [isOpen]);

  return (
    <div className="w-full my-4">
      <div className="border rounded-md shadow-md w-full">
        <button
          onClick={togglePanel}
          className="w-full px-4 py-2 text-left bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 flex justify-between items-center"
        >
          <span>{title}</span>
          <span>{isOpen ? '-' : '+'}</span>
        </button>
        <div
          ref={contentRef}
          style={{ maxHeight: height }}
          className="overflow-hidden transition-max-height duration-500 ease-in-out"
        >
          <div className="px-4 py-2 border-t">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};


export default CollapsiblePanel;