import React, { useState } from 'react';
import { v1 as uuidv1, v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { FaCopy } from 'react-icons/fa'
import { Helmet } from 'react-helmet-async';
import { GoogleAddScript } from '../Constent/Uiconstent';
interface UuidCard {
    id: number;
    uuid: string;
    version: string
}
const UuidGenerator = () => {
    const [uuids, setUuids] = useState<UuidCard[]>([]);
    const [uuidVersion, setUuidVersion] = useState('v4'); // Default to v4 UUID

    const generateUuid = () => {
        let newUuid = '';

        switch (uuidVersion) {
            case 'v1':
                newUuid = uuidv1();
                break;
            case 'v4':
                newUuid = uuidv4();
                break;
            case 'v5':
                newUuid = uuidv5('hello.example.com', uuidv5.DNS);
                break;
            default:
                newUuid = uuidv4();
        }

        setUuids(prevUuids => [
            ...prevUuids,
            { id: Date.now(), uuid: newUuid, version: uuidVersion }
        ]);
    };

    const handleCopyClick = (uuid: string) => {
        // Copy the UUID to clipboard
        navigator.clipboard.writeText(uuid)
            .then(() => alert('UUID copied to clipboard'))
            .catch(err => console.error('Failed to copy: ', err));
    };

    const handleUuidVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUuidVersion(event.target.value);
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen justify-center">
            <Helmet>
                <title>UUID Generator - Coder Utils</title>
                <meta name="description" content="UUID generator ,
                                                    Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
                {/* Add meta tags, links, etc. here if needed */}
            </Helmet>
            <div className="w-full md:w-2/3 md:mx-4 flex-3 flex-col flex items-center relative">
                <div className="p-4 w-full">
                    <h3 className='h3 font-medium text-lg'>UUID Generator</h3>
                    <div className='w-full p-2 '>
                        <label className='h4 font-medium text-md' htmlFor="uuidVersion">UUID Version:</label>
                        <select className='text-medium mr-10 ml-10 p-3 ' id="uuidVersion" value={uuidVersion} onChange={handleUuidVersionChange}>
                            <option value="v1">Version 1 (Time-based)</option>
                            <option value="v4">Version 4 (Random)</option>
                            <option value="v5">Version 5 (Namespace-based)</option>
                        </select>
                        <button className="bg-blue-500 text-white px-4 py-2 rounded" onClick={generateUuid}>Generate UUID</button>
                    </div>
                    <div className='p-4 mt-10 flex flex-wrap w-10/12 flex-row'>
                        {uuids.map(uuidCard => (
                            <div key={uuidCard.id} className='border rounded p-2 m-2 overflow-hidden shadow' >
                                <h3 className=' font-medium text-lg'>Uuid : {uuidCard.version}</h3>
                                <div className='flex items-center'>
                                    <span style={{ flexGrow: 1 }}>{uuidCard.uuid}</span>
                                    <FaCopy className='ml-2' onClick={() => handleCopyClick(uuidCard.uuid)} style={{ cursor: 'pointer' }} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div></div></div>
    );
};

export default UuidGenerator;
