
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CsvOutputOptionState, csvTableStructure, useCsvToSqlConvertContext } from './csvToSqlUtils';

const CsvOutputOptions: React.FC = () => {
  const { options, setOptions, updateCsvTableStructureOptions } = useCsvToSqlConvertContext();

  const handleFiledMappingFieldName = (item: any, event: React.ChangeEvent<HTMLSelectElement>, target: string) => {
    setOptions({
      ...options, fieldMappings: options?.fieldMappings?.map((stop) =>
        stop.id === item.id ? { ...stop, [target]: event.target.value } : stop
      )
    } as CsvOutputOptionState);
  }

  const changeInCsvTableStructure = (item: csvTableStructure, target: string, value: any) => {
    console.log(target, value)
    if (options?.csvTableStructureOption)
      updateCsvTableStructureOptions(options?.csvTableStructureOption?.map((stop) =>
        stop.id == item.id ? { ...stop, [target]: value } : stop
      ))
  }

  return (
    <div className="p-4  w-full">
      <div className="flex mb-4 flex-col">
        <div className='grid grid-flow-col gap-2'>
          <div className='w-full'>
            <label className="block mb-2 text-center"> </label>
          </div>
          <div className='w-full'>
            <label className="block mb-2  text-center">Field #</label>
          </div>
          <div className='w-full'>
            <label className="block mb-2  text-center">Type</label>
          </div>
          <div className='w-full'>
            <label className="block mb-2  text-center">Direction</label>
          </div>
        </div>
        {options?.fieldMappings ? options?.fieldMappings.map((field) => (
          <div className='grid grid-flow-col gap-2'>
            <div className='w-full'>
              <label className="block mb-2">{field.name}</label>
            </div>
            <div className='w-full'>
              <select value={field.fieldName} onChange={(e) => handleFiledMappingFieldName(field, e, 'fieldName')} className="mt-1 w-full p-2 border rounded-md">
                <option value="">Default</option>
                {options?.csvTableStructureOption?.map((item, index) =>
                  <option value={item.head}>{item.head}</option>
                )}
              </select>
            </div>
            <div className='w-full'>
              <select onChange={(e) => handleFiledMappingFieldName(field, e, 'sortBy')} value={field.sortBy} className="mt-1 w-full p-2 border rounded-md">
                <option value="">Default</option>
                <option value="VARCHAR(255)">VARCHAR(255)</option>
                <option value="INTEGER">INTEGER</option>
                <option value="FLOAT">INTEGER</option>
              </select>
            </div>
            <div className='w-full'>
              <select className="mt-1 w-full p-2 border rounded-md" value={field.direction} onChange={(e) => handleFiledMappingFieldName(field, e, 'direction')}>
                <option value="ascending">Ascending</option>
                <option value="descending">Descending</option>
              </select>
            </div>
          </div>
        )) : <></>}
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.setAllFieldsAsVarchar}
          onChange={(e) => setOptions({ ...options, setAllFieldsAsVarchar: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Set all fields as VARCHAR</span>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.keepOriginalFieldNames}
          onChange={(e) => setOptions({ ...options, keepOriginalFieldNames: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Keep original field names as column names</span>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.spacesToUnderscore}
          onChange={(e) => setOptions({ ...options, spacesToUnderscore: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Spaces to Underscore</span>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Enclose field names below with</label>
        <div className="flex space-x-4">
          <label>
            <input
              className='mr-2'
              type="radio"
              name="encloseFieldNames"
              value="default"
              checked={options?.encloseFieldNames === 'default'}
              onChange={(e) => setOptions({ ...options, encloseFieldNames: e.target.value } as CsvOutputOptionState)}
            />
            Default
          </label>
          <label>
            <input
              className='mr-2'
              type="radio"
              name="encloseFieldNames"
              value="quotes"
              checked={options?.encloseFieldNames === 'quotes'}
              onChange={(e) => setOptions({ ...options, encloseFieldNames: e.target.value } as CsvOutputOptionState)}
            />
            Quotes
          </label>
          <label>
            <input
              className='mr-2'
              type="radio"
              name="encloseFieldNames"
              value="backtick"
              checked={options?.encloseFieldNames === 'backtick'}
              onChange={(e) => setOptions({ ...options, encloseFieldNames: e.target.value } as CsvOutputOptionState)}
            />
            Backtick
          </label>
          <label>
            <input
              className='mr-2'
              type="radio"
              name="encloseFieldNames"
              value="brackets"
              checked={options?.encloseFieldNames === 'brackets'}
              onChange={(e) => setOptions({ ...options, encloseFieldNames: e.target.value } as CsvOutputOptionState)}
            />
            Brackets
          </label>
        </div>
        {options?.csvTableStructureOption ? (<div>
          <div className="grid auto-rows-auto text-center text-base font-semibold hover:bg-gray-100 bg-white grid-cols-9 grid-flow-col gap-4">
            <label>Sequence</label>
            <label>Field Name</label>
            <label>Data Type</label>
            <label>include</label>
            <label>Required</label>
            <label>Primary Key</label>
            <label>Upper Case</label>
            <label>Lower Case</label>
            <label>Empty value NULL </label>
          </div>
          {options?.csvTableStructureOption.map((item, index) => (<div className='grid text-center auto-rows-auto hover:bg-gray-100 bg-white grid-cols-9 grid-flow-col gap-4' key={item.id}>
            <label>{index + 1}</label>
            <div ><input type='text' className="mt-1 w-full p-2 border rounded-md" value={item.head2} onChange={(e) => changeInCsvTableStructure(item, 'head2', e.target.value)}></input></div>
            <select value={item.sqlType} onChange={(e) => changeInCsvTableStructure(item, 'include', e.target.value)} className="mt-1 w-full p-2 border rounded-md">
              <option value="VARCHAR(255)">VARCHAR(255)</option>
              <option value="INTEGER">INTEGER</option>
              <option value="FLOAT">INTEGER</option>
            </select>
            <div><input type='checkbox' checked={item.include} onChange={(e) => changeInCsvTableStructure(item, 'include', e.target.checked)}></input></div>
            <div><input type='checkbox' checked={item.isRequired} onChange={(e) => changeInCsvTableStructure(item, 'isRequired', e.target.checked)}></input></div>
            <div><input type='checkbox' checked={item.key} onChange={(e) => changeInCsvTableStructure(item, 'key', e.target.checked)}></input></div>
            <div><input type='checkbox' checked={item.upper} onChange={(e) => changeInCsvTableStructure(item, 'upper', e.target.checked)}></input></div>
            <div><input type='checkbox' checked={item.lower} onChange={(e) => changeInCsvTableStructure(item, 'lower', e.target.checked)}></input></div>
            <div><input type='checkbox' checked={item.emptyValueNull} onChange={(e) => changeInCsvTableStructure(item, 'emptyValueNull', e.target.checked)} /></div>
          </div>))}
        </div>) : <></>}
      </div>
      <div className="mb-4">
        <label className="block mb-2">Schema.Table or View Name:</label>
        <input
          type="text"
          value={options?.schemaTableName}
          onChange={(e) => setOptions({ ...options, schemaTableName: e.target.value } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.dropTable}
          onChange={(e) => setOptions({ ...options, dropTable: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Drop Table/View</span>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.createTable}
          onChange={(e) => setOptions({ ...options, createTable: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Create Table/View</span>
      </div>
      <div className="mb-4">
        <input
          type="checkbox"
          checked={options?.databaseType == undefined ? false : true}
          className="mr-2"
          onChange={(e) => setOptions({ ...options, databaseType: e.target.checked ? '' : undefined } as CsvOutputOptionState)}
        />
        <label >Include Auto Increment Clause on single numeric Primary Key using</label>
        <select value={options?.databaseType} disabled={options?.databaseType == undefined ? true : false} className="mt-1 p-2 border rounded-md w-full" onChange={(e) => setOptions({ ...options, databaseType: e.target.value } as CsvOutputOptionState)}>
          <option value="">{''}</option>
          <option value="mysql">{'mySql -> AUTO_INCREMENT'}</option>
          <option value="sqlserver">{'sqlserver -> IDENTITY(1,1)'}</option>
          <option value="postgresql">{'postgresql -> SERIAL'}</option>
        </select>
      </div>
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.replaceInsert}
          onChange={(e) => setOptions({ ...options, replaceInsert: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Use REPLACE instead</span>
      </div>
      <div className="mb-4">
        <input type='checkbox' checked={options?.multipleValuesClause != -1}
          onChange={(e) => setOptions({ ...options, multipleValuesClause: e.target.checked ? 0 : -1 } as CsvOutputOptionState)} className="mr-2" />
        <label>Use one INSERT/REPLACE statement with multiple VALUES clauses - How many?</label>
        <input
          type="number"
          disabled={options?.multipleValuesClause == -1}
          value={options?.multipleValuesClause}
          onChange={(e) => setOptions({ ...options, multipleValuesClause: Number(e.target.value) } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2"></label>
        <input type='checkbox' checked={options?.phrase != undefined ? true : false}
          onChange={(e) => setOptions({ ...options, phrase: e.target.checked ? '' : undefined } as CsvOutputOptionState)} className="mr-2" />
        <label>Add phrase after INSERT/UPDATE keyword:</label>
        <input
          type="text"
          disabled={options?.phrase == undefined ? true : false}
          value={options?.phrase == undefined ? '' : options?.phrase}
          onChange={(e) => setOptions({ ...options, phrase: e.target.value } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div>
      {/* <div className="mb-4">
        <label className="block mb-2">System TABLE needed to SELECT from? Table Name:</label>
        <input
          type="text"
          value={options?.selectTableName}
          onChange={(e) => setOptions({ ...options, selectTableName: e.target.value } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Batch how many rows together via UNION ALL?</label>
        <input
          type="text"
          value={options?.batchUnions}
          onChange={(e) => setOptions({ ...options, batchUnions: e.target.value } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div> */}
      <div className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={options?.escapeSQLStrings}
          onChange={(e) => setOptions({ ...options, escapeSQLStrings: e.target.checked } as CsvOutputOptionState)}
        />
        <span>Escape SQL Strings With Backslash (MySQL style)</span>
      </div>
      <div className="mb-4">
        <label className="block mb-2">Output Format for Dates</label>
        <input
          type="text"
          value={options?.outputFormat}
          onChange={(e) => setOptions({ ...options, outputFormat: e.target.value } as CsvOutputOptionState)}
          className="mt-1 p-2 border rounded-md w-full"
        />
      </div>
    </div>
  );
};

export default CsvOutputOptions;