
import React, { useState } from 'react';
import { useCsvToSqlConvertContext, CsvInputOptionsState } from './csvToSqlUtils';

const CsvInputOptions: React.FC = () => {
    const { inputOption, updateInputOption } = useCsvToSqlConvertContext()

    return (
        <div className=" w-full">
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={inputOption?.firstRowAsColumnNames}
                        onChange={(e) => updateInputOption({ ...inputOption, firstRowAsColumnNames: e.target.checked } as CsvInputOptionsState)}
                    />
                    <span>First row is column names</span>
                </label>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                    <label className="block">Limit # of lines:</label>
                    <input
                        type="number"
                        value={inputOption?.limitLines}
                        onChange={(e) => updateInputOption({ ...inputOption, limitLines: Number(e.target.value) } as CsvInputOptionsState)}
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
                <div>
                    <label className="block">Skip # of Lines:</label>
                    <input
                        type="number"
                        value={inputOption?.skipLines}
                        onChange={(e) => updateInputOption({ ...inputOption, skipLines: Number(e.target.value) } as CsvInputOptionsState)}
                        className="mt-1 p-2 border rounded-md w-full"
                    />
                </div>
            </div>
            <div className="mb-4">
                <label className="block mb-2">Field Separator:</label>
                <div className="space-x-2 flex flex-row gap-2">
                    {['Auto Detect', ',', ';', '|', 'Tab', 'Caret-^', 'Space'].map((separator) => (
                        <label key={separator} className="flex items-center space-x-1">
                            <input
                                type="radio"
                                name="fieldSeparator"
                                value={separator}
                                checked={inputOption?.fieldSeparator === separator}
                                onChange={(e) => updateInputOption({ ...inputOption, fieldSeparator: e.target.value } as CsvInputOptionsState)}
                            />
                            <span>{separator}</span>
                        </label>
                    ))}
                </div>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={inputOption?.replaceAccents}
                        onChange={(e) => updateInputOption({ ...inputOption, replaceAccents: e.target.checked } as CsvInputOptionsState)}
                    />
                    <span>Replace Accents/Diacriticals</span>
                </label>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={inputOption?.treatQuotesAsData}
                        onChange={(e) => updateInputOption({ ...inputOption, treatQuotesAsData: e.target.checked } as CsvInputOptionsState)}
                    />
                    <span>Treat all Quoting Character as data</span>
                </label>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={inputOption?.csvQuotingCharacter}
                        onChange={(e) => updateInputOption({ ...inputOption, csvQuotingCharacter: e.target.checked } as CsvInputOptionsState)}
                    />
                    <span>Input CSV Quoting Character is Apostrophe</span>
                </label>
            </div>
            <div className="mb-4">
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={inputOption?.csvContainsBackslashEscaping}
                        onChange={(e) => updateInputOption({ ...inputOption, csvContainsBackslashEscaping: e.target.checked } as CsvInputOptionsState)}
                    />
                    <span>CSV contains backslash escaping like \n, \t, and \\</span>
                </label>
            </div>
        </div>
    );
};

export default CsvInputOptions;