import React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { GoogleAddScript } from '../Constent/Uiconstent';
import { navigation } from '../Constent/Uiconstent'

const MainPage: React.FC = () => {
    return (
        <div className="bg-gray-100 min-h-screen">
            <Helmet>
                <title>Coder Utils</title>
                <meta name="description" content="Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
                {/* Add meta tags, links, etc. here if needed */}
            </Helmet>
            <div className="py-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">Coder Utils</h2>
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Essential Utilities for Developers
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                            Your go-to tools for everyday coding tasks.
                        </p>
                    </div>

                    <div className="mt-10">
                        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                            {navigation.map(e => {
                                return <NavLink to={e.href} className="bg-white overflow-hidden shadow rounded-lg">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="flex items-center">
                                            <div className="ml-2 w-0 flex-1 truncate">
                                                <p className="text-sm font-medium text-indigo-600 truncate">{e.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </NavLink>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainPage;
