import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AppNevbar from './nevbar/AppNevbar';
import AdvertisementComponent from './MainArea/MainArea';
import RegexTester from './MainArea/RegexTester';
import CodeMinifier from './MainArea/CodeMinifier';
import ColorConverter from './MainArea/ColorConverter';
import GradientMaker from './MainArea/GradientMaker';
import HtmlPrettifier from './MainArea/HtmlPrettifier';
import UuidGenerator from './MainArea/UuidGenerator';
import MainPage from './MainArea/MainPage';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CsvToSqlConverter from './MainArea/SqlConverter';
import { CsvToSqlConvertProvider } from './utils/csvToSqlUtils';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="google-adsense-account" content="ca-pub-9494547509817371" />
      </Helmet>
      <Router>
        <AppNevbar></AppNevbar>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/converter/:type" element={<AdvertisementComponent />} />
          <Route path="Regex-Tester" element={<RegexTester />} />
          <Route path="CodeMinifier" element={<CodeMinifier />} />
          <Route path="Csv-To-Sql-Converter" element={<CsvToSqlConvertProvider><CsvToSqlConverter /></CsvToSqlConvertProvider>} />
          <Route path="Html-Prettifier" element={<HtmlPrettifier />} />
          <Route path="Gradient-Maker" element={<GradientMaker />} />
          <Route path="Uuid-Generator" element={<UuidGenerator />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
