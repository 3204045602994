import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { GoogleAddScript } from '../Constent/Uiconstent';


const regexPatterns = [
    { id: "email-validation", title: "Email Validation", description: "Ensure an input string is a valid email address.", pattern: "[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$" },
    { id: "url-validation", title: "URL Validation", description: "Check if a string is a valid URL.", pattern: "(https?|ftp):\\/\\/[^\\s/$.?#].[^\\s]*$" },
    { id: "password-validation", title: "Password Validation", description: "Validate a strong password (minimum 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character).", pattern: "/(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}" },
    { id: "numeric-input", title: "Numeric Input", description: "Accept only numeric input (positive integers).", pattern: "\\d+$" },
    { id: "date-format", title: "Date Format", description: "Validate date format (YYYY-MM-DD).", pattern: "\\d{4}-\\d{2}-\\d{2}$" },
    { id: "html-tags", title: "HTML Tags", description: "Match HTML tags (opening and closing).", pattern: "/<\\/?[\\w\\s=\"/.':;#-\\/\\?]+>" },
    { id: "phone-number", title: "Phone Number", description: "Validate a phone number (US format).", pattern: "\\(\\d{3}\\) \\d{3}-\\d{4}$" },
    { id: "zip-code", title: "ZIP Code", description: "Validate a US ZIP code.", pattern: "\\d{5}(-\\d{4})?$" },
    { id: "credit-card", title: "Credit Card", description: "Validate a credit card number.", pattern: "(\\d{4}[- ]?){3}\\d{4}$" },
    { id: "ipv4-address", title: "IPv4 Address", description: "Validate an IPv4 address.", pattern: "(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)){3}$" },
    { id: "ipv6-address", title: "IPv6 Address", description: "Validate an IPv6 address.", pattern: "([\\da-fA-F]{1,4}:){7}[\\da-fA-F]{1,4}$" },
    { id: "mac-address", title: "MAC Address", description: "Validate a MAC address.", pattern: "([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$" },
    { id: "slug", title: "Slug", description: "Validate a URL slug (lowercase letters, numbers, hyphens).", pattern: "[a-z0-9]+(?:-[a-z0-9]+)*$" },
    { id: "username", title: "Username", description: "Validate a username (alphanumeric, underscores, 3-16 characters).", pattern: "[a-zA-Z0-9_]{3,16}$" },
    { id: "hex-color", title: "Hex Color", description: "Validate a hex color code.", pattern: "#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$" },
    { id: "rgb-color", title: "RGB Color", description: "Validate an RGB color code.", pattern: "rgb\\((\\d{1,3}), (\\d{1,3}), (\\d{1,3})\\)$" },
    { id: "uuid", title: "UUID", description: "Validate a UUID.", pattern: "[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$" },
    { id: "isbn", title: "ISBN", description: "Validate an ISBN (10 or 13 digits).", pattern: "(97(8|9))?\\d{9}(\\d|X)$" },
    { id: "ssn", title: "SSN", description: "Validate a US Social Security Number.", pattern: "\\d{3}-\\d{2}-\\d{4}$" },
    { id: "postal-code", title: "Postal Code", description: "Validate a postal code.", pattern: "[A-Z0-9][A-Z0-9\\- ]{0,10}[A-Z0-9]$" },
    { id: "time-24h", title: "24-Hour Time", description: "Validate 24-hour time format (HH:MM).", pattern: "([01]?[0-9]|2[0-3]):[0-5][0-9]$" },
    { id: "time-12h", title: "12-Hour Time", description: "Validate 12-hour time format (HH:MM AM/PM).", pattern: "(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$" },
    { id: "html-entity", title: "HTML Entity", description: "Match HTML entities.", pattern: "/&[a-zA-Z][a-zA-Z0-9]+;" },
    { id: "non-ascii", title: "Non-ASCII Characters", description: "Match non-ASCII characters.", pattern: "/[^\\x00-\\x7F]" },
    { id: "whitespace", title: "Whitespace Characters", description: "Match whitespace characters.", pattern: "/\\s" },
    { id: "non-whitespace", title: "Non-Whitespace Characters", description: "Match non-whitespace characters.", pattern: "/\\S" },
    { id: "word-boundary", title: "Word Boundary", description: "Match a word boundary.", pattern: "/\\b" },
    { id: "non-word-boundary", title: "Non-Word Boundary", description: "Match a non-word boundary.", pattern: "/\\B" },
    { id: "digit", title: "Digit", description: "Match a digit.", pattern: "/\\d" },
    { id: "non-digit", title: "Non-Digit", description: "Match a non-digit.", pattern: "/\\D" },
    { id: "word-character", title: "Word Character", description: "Match a word character (alphanumeric and underscore).", pattern: "/\\w" },
    { id: "non-word-character", title: "Non-Word Character", description: "Match a non-word character.", pattern: "/\\W" },
    { id: "start-of-string", title: "Start of String", description: "Match the start of a string.", pattern: "/" },
    { id: "end-of-string", title: "End of String", description: "Match the end of a string.", pattern: "/$/" },
    { id: "escape-sequence", title: "Escape Sequence", description: "Match an escape sequence.", pattern: "/\\\\" },
    { id: "email-domain", title: "Email Domain", description: "Match the domain part of an email.", pattern: "/@([\\w-]+\\.)+[\\w-]{2,4}$" },
    { id: "username-alphanumeric", title: "Alphanumeric Username", description: "Match an alphanumeric username (3-16 characters).", pattern: "[a-zA-Z0-9]{3,16}$" },
    { id: "date-mmddyyyy", title: "Date (MM/DD/YYYY)", description: "Match a date in MM/DD/YYYY format.", pattern: "(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])/\\d{4}$" },
    { id: "date-ddmmyyyy", title: "Date (DD/MM/YYYY)", description: "Match a date in DD/MM/YYYY format.", pattern: "(0[1-9]|[12][0-9]|3[01])/(0[1-9]|1[0-2])/\\d{4}$" },
    { id: "credit-card-visa", title: "Visa Credit Card", description: "Match a Visa credit card number.", pattern: "4[0-9]{12}(?:[0-9]{3})?$" },
    { id: "credit-card-mastercard", title: "MasterCard Credit Card", description: "Match a MasterCard credit card number.", pattern: "5[1-5][0-9]{14}$" },
    { id: "credit-card-amex", title: "American Express Credit Card", description: "Match an American Express credit card number.", pattern: "3[47][0-9]{13}$" },
    { id: "credit-card-diners", title: "Diners Club Credit Card", description: "Match a Diners Club credit card number.", pattern: "3(?:0[0-5]|[68][0-9])[0-9]{11}$" },
    { id: "credit-card-discover", title: "Discover Credit Card", description: "Match a Discover credit card number.", pattern: "6(?:011|5[0-9]{2})[0-9]{12}$" },
    { id: "credit-card-jcb", title: "JCB Credit Card", description: "Match a JCB credit card number.", pattern: "(?:2131|1800|35\\d{3})\\d{11}$" },
    { id: "time-hhmm", title: "Time (HH:MM)", description: "Match time in HH:MM format.", pattern: "([01]?\\d|2[0-3]):[0-5]\\d$" },
    { id: "time-hhmmss", title: "Time (HH:MM:SS)", description: "Match time in HH:MM:SS format.", pattern: "([01]?\\d|2[0-3]):[0-5]\\d:[0-5]\\d$" },
    { id: "base64", title: "Base64", description: "Match a Base64 encoded string.", pattern: "(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\\/]{3}=)?$" },
    { id: "ipv4-range", title: "IPv4 Range", description: "Match an IPv4 address range.", pattern: "(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)){3}-(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d{2}|[1-9]?\\d)){3}$" },
    { id: "ipv6-range", title: "IPv6 Range", description: "Match an IPv6 address range.", pattern: "([\\da-fA-F]{1,4}:){7}[\\da-fA-F]{1,4}-([\\da-fA-F]{1,4}:){7}[\\da-fA-F]{1,4}$" },
];
const RegexTester: React.FC = () => {
    const [regex, setRegex] = useState('');
    const [text, setText] = useState('');
    const [matches, setMatches] = useState<string[]>([]);
    const [dirty, setDirty] = useState<boolean>(false)

    const handleTest = () => {
        try {
            const pattern = new RegExp(regex, 'g');
            const foundMatches = text.match(pattern) || [];
            setMatches(foundMatches);
            setDirty(true);
        } catch (error) {
            setMatches(['Invalid regex']);
        }
    };

    return (
        <div className='flex flex-col justify-center items-center'>
            <Helmet>
                <title>Regex Tester - Coder Utils</title>
                <meta name="description" content="Regex Tester ,Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
                {/* Add meta tags, links, etc. here if needed */}
            </Helmet>
            <div className="min-w-xl mx-auto p-4 w-8/12">
                <h1 className="text-2xl font-bold mb-4">Regex Tester</h1>
                <div className="mb-4 w-full">
                    <label className="block text-sm font-medium mb-1" htmlFor="regex">
                        Regex Pattern
                    </label>
                    <input
                        type="text"
                        id="regex"
                        value={regex}
                        onChange={(e) => setRegex(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        placeholder="Enter your regex pattern"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-sm font-medium mb-1" htmlFor="text">
                        Text to Test
                    </label>
                    <textarea
                        id="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                        rows={6}
                        placeholder="Enter text to test against the regex"
                    />
                </div>
                <button
                    onClick={handleTest}
                    className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                >
                    Test Regex
                </button>
                <div>
                    <h2 className="text-xl font-bold mb-2">Matches</h2>
                    {dirty && matches.length == 0 ? <div className='text-red-500 mt-4'><p className='text-red-500 '>{`No Matches`}</p></div> : <></>}
                    <ul className="list-disc pl-5">
                        {matches.map((match, index) => (
                            <li key={index}>{match}</li>
                        ))}
                    </ul>
                </div>

            </div>
            <div className='w-8/12'>
                <h1 className="text-3xl font-bold mb-4">Popular Regex Patterns Used in Coding</h1>
                <div className="flex flex-wrap">
                    <aside className="w-full md:w-1/4 bg-gray-200 p-4 rounded-md shadow-md mb-4 md:mb-0">
                        <h2 className="text-xl font-bold mb-2">Quick Reference</h2>
                        <ul className="list-disc list-inside space-y-1">
                            {regexPatterns.map((pattern) => (
                                <li key={pattern.id}>
                                    <a href={`#${pattern.id}`} className="text-blue-500 hover:underline">{pattern.title}</a>
                                </li>
                            ))}
                        </ul>
                    </aside>

                    <main className="w-full md:w-3/4 md:pl-6">
                        {regexPatterns.map((pattern) => (
                            <section id={pattern.id} key={pattern.id} className="bg-gray-100 p-4 rounded-md shadow-md mb-4">
                                <h2 className="text-xl font-bold mb-2">{pattern.title}</h2>
                                <p>{pattern.description}</p>
                                <code className="bg-gray-200 p-2 rounded-md block">{pattern.pattern}</code>
                            </section>
                        ))}
                        <p className="text-gray-600">These are just a few examples of regex patterns commonly used in coding. Regex can be more complex depending on specific requirements.</p>

                    </main>
                </div>
            </div>
        </div>
    );
};

export default RegexTester;