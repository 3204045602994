import React from "react";
import { jsonToCSharpClass, jsonToFlutterClass, jsonToPythonClass, jsonToRubyClass, jsonToTypeScriptClass, jsonToVBClass } from "../functions";

export const ConverterConstent: { [key: string]: { title: string, action: (jsonObject: Record<string, any>, className: string) => string, nevigtion: string } } = {
    'csharp': { title: "Convert Json to C# class", action: jsonToCSharpClass, nevigtion: 'json-to-csharp' },
    'vb': { title: 'Convert Json to VB class', action: jsonToVBClass, nevigtion: 'json-to-vb' },
    'python': { title: 'Convert Json to Python class', action: jsonToPythonClass, nevigtion: 'json-to-python' },
    'typeScript': { title: 'Convert Json to TypeScript class', action: jsonToTypeScriptClass, nevigtion: 'json-to-typeScript' },
    'ruby': { title: 'Convert Json to Ruby class', action: jsonToRubyClass, nevigtion: 'json-to-ruby' },
    'dart': { title: 'Convert Json to dart class', action: jsonToFlutterClass, nevigtion: 'json-to-dart' }
}

export const navigation = [
    { name: 'Dashboard', href: '/', current: true },
    { name: 'Json to Class', href: '/converter/json-to-csharp', current: false },
    { name: 'UUID generator', href: '/Uuid-Generator', current: false },
    { name: 'Csv to Sql', href: '/Csv-To-Sql-Converter', current: false },
    { name: 'Html Prettifier', href: '/Html-Prettifier', current: false },
    { name: 'Gradient Maker', href: '/Gradient-Maker', current: false },
    { name: 'Regex Tester', href: '/Regex-Tester', current: false },
  ]
export const GoogleAddScript: React.FC = () => { return (<meta name="google-adsense-account" content="ca-pub-9494547509817371" />) }