
import React, { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const tabs = [
    { label: 'CSV To SQL Insert', key: 'insert' },
    { label: 'CSV To SQL Update', key: 'update' },
    { label: 'CSV To SQL Delete', key: 'delete' },
    { label: 'CSV To SQL Merge', key: 'merge' },
    { label: 'CSV To SQL Select', key: 'select' },
];

interface CsvToSqlTabsProps {
    resultData: string;
}

const CsvToSqlTabs: React.FC<CsvToSqlTabsProps> = ({ resultData }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].key);

    return (
        <div>
            <div className="flex mb-4 border-b">
                {tabs.map((tab) => (
                    <button
                        key={tab.key}
                        onClick={() => setActiveTab(tab.key)}
                        className={`px-4 py-2 ${activeTab === tab.key ? 'border-b-2 border-blue-500' : ''
                            }`}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="p-4 border rounded-md">
                <h3 className="text-lg font-bold mb-2">Result Data:</h3>
                <pre className="bg-gray-100 p-4 rounded-md overflow-auto">
                    {resultData}
                </pre>
            </div>
        </div>
    );
};

export default CsvToSqlTabs;

export const parseCsv = (csvData: string, options: any) => {
    // Implement CSV parsing logic here
    // Consider options such as skip lines, limit lines, etc.
    // Return parsed data in a suitable format (e.g., array of objects)

    // Example simple CSV parsing
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    const data = lines.slice(1).map(line => {
        const values = line.split(',');
        return headers.reduce((obj: any, header, index) => {
            obj[header] = values[index];
            return obj;
        }, {});
    });

    return data;
};

export const generateSql = (parsedData: any[], inputOptions: any, outputOptions: any) => {
    // Implement SQL generation logic here based on options
    // Example simple SQL generation

    const tableName = outputOptions.schemaTableName || 'mytable';
    const columns = Object.keys(parsedData[0]).map(col => `"${col}" VARCHAR(255)`).join(', ');

    let sql = `CREATE TABLE ${tableName} (${columns});\n`;

    parsedData.forEach(row => {
        const values = Object.values(row).map(val => `'${val}'`).join(', ');
        sql += `INSERT INTO ${tableName} VALUES (${values});\n`;
    });

    return sql;
};

export const determineDataType = (columnData: string[]): string => {
    let isInteger = true;
    let isFloat = true;

    for (const value of columnData) {
        if (value === '') continue; // Skip empty values
        if (!/^\d+$/.test(value)) isInteger = false;
        if (!/^\d+(\.\d+)?$/.test(value)) isFloat = false;
        if (!isInteger && !isFloat) return 'VARCHAR(255)';
    }
    if (isInteger) return 'INTEGER';
    if (isFloat) return 'FLOAT';
    return 'VARCHAR(255)';
};

export interface csvTableStructure {
    id: string,
    head: string,
    head2: string,
    key: boolean,
    sqlType: string,
    upper: boolean,
    lower: boolean,
    emptyValueNull: boolean,
    include: boolean,
    isRequired: boolean
}

interface FieldMapping {
    id: string;
    fieldName: string;
    sortBy: string;
    name: string;
    direction: 'ascending' | 'descending';
}

export interface CsvOutputOptionState {
    fieldMappings: FieldMapping[] | null | undefined;
    csvTableStructureOption: csvTableStructure[] | null | undefined;
    outputFormat: string;
    setAllFieldsAsVarchar: boolean;
    keepOriginalFieldNames: boolean;
    spacesToUnderscore: boolean;
    encloseFieldNames: string;
    schemaTableName: string;
    dropTable: boolean;
    createTable: boolean;
    replaceInsert: boolean;
    multipleValuesClause: number;
    selectTableName: string;
    batchUnions: string;
    escapeSQLStrings: boolean;
    phrase: string | undefined;
    databaseType: string | undefined;
}

export interface CsvInputOptionsState {
    firstRowAsColumnNames: boolean | undefined;
    limitLines: number | undefined;
    skipLines: number | undefined;
    fieldSeparator: string | undefined;
    replaceAccents: boolean | undefined;
    treatQuotesAsData: boolean | undefined;
    csvQuotingCharacter: boolean | undefined;
    csvContainsBackslashEscaping: boolean | undefined;
}

export interface CsvOutputOptions {
    options: CsvOutputOptionState | null,
    inputOption: CsvInputOptionsState | null,
    updateInputOption: (newValue: CsvInputOptionsState) => void;
    csvTableStructureOptions: csvTableStructure[] | null;
    setOptions: (newUser: CsvOutputOptionState) => void;
    updateCsvTableStructureOptions: (newValue: csvTableStructure[]) => void;
}


export const CsvToSqlConvertContext = createContext<CsvOutputOptions | undefined>(undefined);

export const CsvToSqlConvertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [options, setOptions] = useState<any>({
        fieldMappings: [
            { id: uuidv4(), fieldName: 'Default', sortBy: 'Default', name: 'First By', direction: 'ascending' },
            { id: uuidv4(), fieldName: 'Default', sortBy: 'Default', name: 'Then By', direction: 'ascending' },
            { id: uuidv4(), fieldName: 'Default', sortBy: 'Default', name: 'Then By', direction: 'ascending' },
        ],
        outputFormat: 'YYYY-MM-DD',
        setAllFieldsAsVarchar: false,
        keepOriginalFieldNames: false,
        spacesToUnderscore: false,
        encloseFieldNames: '',
        schemaTableName: 'mytable',
        dropTable: false,
        createTable: false,
        replaceInsert: false,
        multipleValuesClause: -1,
        selectTableName: '',
        batchUnions: '',
        escapeSQLStrings: false,
        phrase: '',
        databaseType: undefined
    });

    const [csvInputOptions, setCsvInputOptions] = useState<CsvInputOptionsState>({
        firstRowAsColumnNames: true,
        limitLines: undefined,
        skipLines: 0,
        fieldSeparator: 'Auto Detect',
        replaceAccents: false,
        treatQuotesAsData: false,
        csvQuotingCharacter: false,
        csvContainsBackslashEscaping: false,
    });

    const copyOfTableStructure = useRef<csvTableStructure[] | null>(null);
    const [csvTableStructureOptions, setcsvTableStructureOptions] = useState<csvTableStructure[] | null>(null);

    const updateCsvTableStructureOptions = (newValue: csvTableStructure[]) => {
        if (!csvTableStructureOptions)
            copyOfTableStructure.current = newValue;
        setcsvTableStructureOptions(newValue);
    }

    const updateOpion = (newUser: CsvOutputOptionState) => {
        setOptions(newUser);
        if (newUser.keepOriginalFieldNames) {
            setcsvTableStructureOptions(copyOfTableStructure.current);
        }
    };
    const updateInputOption = (newValue: CsvInputOptionsState) => {
        setCsvInputOptions(newValue);
    }

    return (
        <CsvToSqlConvertContext.Provider value={{ inputOption: csvInputOptions, updateInputOption: updateInputOption, csvTableStructureOptions, options: { ...options, csvTableStructureOption: csvTableStructureOptions } as CsvOutputOptionState, updateCsvTableStructureOptions, setOptions: updateOpion }}>
            {children}
        </CsvToSqlConvertContext.Provider>
    );
};

export const useCsvToSqlConvertContext = (): CsvOutputOptions => {
    const context = useContext(CsvToSqlConvertContext);
    if (context === undefined) {
        throw new Error('useCsvToSqlConvertContext must be used within a CsvToSqlConvertContext');
    }
    return context;
};

function sortRows(rows: any[], sortConfig: FieldMapping[]) {
    // Create a comparator function based on the sort configuration
    const comparator = (a: any, b: any) => {
        for (const { fieldName, direction, sortBy } of sortConfig) {
            // Handle case where fieldName is not a key in the objects
            if (a[fieldName] === undefined || b[fieldName] === undefined) {
                continue;
            }

            // Compare values
            let comparison = 0;
            if ((sortBy === "INTEGER" || sortBy === "FLOAT") && Number(a[fieldName]) > Number(b[fieldName])) {
                comparison = 1;
            } else if ((sortBy === "INTEGER" || sortBy === "FLOAT") && Number(a[fieldName]) < Number(b[fieldName])) {
                comparison = -1;
            } else if (sortBy == "VARCHAR(255)") {
                comparison = a[fieldName].localeCompare(b[fieldName])
            }

            // If the comparison result is not zero, return based on direction
            if (comparison !== 0) {
                return direction === 'ascending' ? comparison : -comparison;
            }
        }

        // If all comparisons are equal, return 0
        return 0;
    };
    // Sort the rows using the comparator
    return rows.sort(comparator);
}

export const encloseString = (encloseFieldNames: string, reverse: boolean = false) => {
    if (encloseFieldNames === "quotes") {
        return "'";
    } else if (encloseFieldNames === "backtick") {
        return "`";
    } else if (encloseFieldNames === "brackets") {
        return "[";
    } else if (reverse && encloseFieldNames === "brackets") {
        return "]";
    } else {
        return "";
    }
}

const getColumneString = (option: CsvOutputOptionState) => {
    let sql = "";
    if (option.replaceInsert) {
        sql += `INSERT OR REPLACE ${option.phrase} INTO ${option.schemaTableName} (`;
    } else {
        sql += `INSERT ${option.phrase} INTO ${option.schemaTableName} (`;
    }

    const columns = option.csvTableStructureOption?.filter(field => field.include).map(field => {
        let fieldName = field.head2;
        if (option.spacesToUnderscore) {
            fieldName = fieldName.replace(/\s+/g, '_');
        }
        if (option.encloseFieldNames) {
            fieldName = `${encloseString(option.encloseFieldNames)}${fieldName}${encloseString(option.encloseFieldNames, true)}`;
        }
        return fieldName;
    });

    sql += columns?.join(', ') + ')';
    return sql
}

const getRowString = (option: CsvOutputOptionState, row: any) => {
    let sql = '';
    const values = option.csvTableStructureOption?.filter(field => field.include).map(field => {
        let value = option.escapeSQLStrings && typeof row[field.head] === 'string' ? `'${row[field.head].replace(/'/g, "''")}'` : typeof row[field.head] === 'string' ? `'${row[field.head]}'` : row[field.head];
        if (field.upper) {
            value = `UPPER(${value})`;
        } else if (field.lower) {
            value = `LOWER(${value})`;
        }
        return value;
    });

    sql += values?.join(', ') + ')';
    return sql;
}

const getAutoIncrementSyntax = (option: CsvOutputOptionState): string => {
    switch (option.databaseType) {
        case 'mysql':
            return 'AUTO_INCREMENT';
        case 'sqlserver':
            return 'IDENTITY(1,1)';
        case 'postgresql':
            return 'SERIAL';
        default:
            return '';
    }
}

export const generateSQL = (option: CsvOutputOptionState, inputOption: CsvInputOptionsState, rows: any[]) => {

    if (inputOption.skipLines && inputOption.limitLines)
        rows = rows.slice(inputOption.skipLines, inputOption.limitLines)
    else if (inputOption.skipLines)
        rows = rows.slice(inputOption.skipLines)
    else if (inputOption.limitLines)
        rows = rows.slice(0, inputOption.limitLines)


    if (option.fieldMappings && option.fieldMappings.length == 3) {
        const sortFields = option.fieldMappings.filter(a => a.fieldName != 'Default' && a.sortBy != 'Default');
        rows = sortRows(rows, sortFields)
    }
    let sql = '';



    if (option.dropTable) {
        sql += `DROP TABLE IF EXISTS ${option.schemaTableName};\n`;
    }

    if (option.createTable && option.csvTableStructureOption) {
        sql += `CREATE TABLE ${option.schemaTableName} (\n`;
        option.csvTableStructureOption.forEach((field, index) => {
            if (!field.include) return;

            let fieldName = field.head2;
            if (option.spacesToUnderscore) {
                fieldName = fieldName.replace(/\s+/g, '_');
            }
            if (option.encloseFieldNames) {
                fieldName = `${encloseString(option.encloseFieldNames)}${fieldName}${encloseString(option.encloseFieldNames, true)}`;
            }
            let fieldType = option.setAllFieldsAsVarchar ? 'VARCHAR' : field.sqlType;
            sql += `  ${fieldName} ${fieldType}`;
            if (field.key) {
                sql += ` PRIMARY KEY ${getAutoIncrementSyntax(option)}`;
            }
            if (field.isRequired) {
                sql += ' NOT NULL';
            }

            if (field.emptyValueNull && !field.isRequired) {
                sql += ' DEFAULT NULL';
            }

            const nextIndex = option.csvTableStructureOption?.filter(f => f.include).length;
            if (nextIndex && index < nextIndex - 1) {
                sql += ',';
            }
            sql += '\n';
        });
        sql += ');\n';
    }

    if (option.multipleValuesClause <= 0) {
        rows.map((row, index) => {
            sql += getColumneString(option) + ' VALUES (';
            sql += getRowString(option, row) + ';\n';
        })
    } else {
        rows.map((row, index) => {
            if (index == 0 || rows.length - index <= option.multipleValuesClause || index % option.multipleValuesClause == 0)
                sql += getColumneString(option) + '\n';
            sql += ' VALUES ('
            sql += getRowString(option, row) + ',\n';
        })
    }

    // if (multipleValuesClause) {
    //     sql += `${multipleValuesClause};\n`;
    // }

    // if (selectTableName) {
    //     sql += `SELECT * FROM ${selectTableName};\n`;
    // }

    // if (batchUnions) {
    //     sql += `${batchUnions};\n`;
    // }

    return sql;
};