import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConverterConstent, GoogleAddScript } from '../Constent/Uiconstent'
import { Helmet } from 'react-helmet-async';

interface ConverterParam {
    type: string
    [key: string]: string | undefined; // Allow additional string properties
}
const AdvertisementComponent: React.FC = () => {
    // State for text areas
    const { type } = useParams<ConverterParam>();
    const [selectedKey, setSelectedKey] = useState<string>('')
    const [jsonText, setJsonText] = useState<string>('');
    const [csharpText, setCsharpText] = useState<string>('');
    const [error, SetError] = useState<string>('');
    const [TitleText, setTitleText] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        if (type && !Object.values(ConverterConstent).map(e => e.nevigtion).find(e => e === type)) {
            navigate('/error');
        }
        setSelectedKey(Object.keys(ConverterConstent).find(key => ConverterConstent[key].nevigtion === type) as string)
        if (selectedKey)
            setTitleText(ConverterConstent[selectedKey].title);
    }, [type, selectedKey])


    // Function to clear JSON text area
    const handleClear = () => {
        setJsonText('');
    };

    // Function to convert JSON to C#
    const handleConvert = () => {
        try {
            if (selectedKey) {
                const action = ConverterConstent[selectedKey].action;
                setCsharpText(action(JSON.parse(jsonText), "root"));
            }
        } catch (ex) {
            SetError('Sorry! unable to convert Json to ' + selectedKey)
        }
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen justify-center">
            <Helmet>
                <title>JSON to {selectedKey} Converter - Coder Utils</title>
                <meta name="description" content={` Convert any JSON object to ${selectedKey} classes online.
                                                    CoderUtils is a free toolkit that will help you
                                                    generate ${selectedKey} classes on the fly.
                                                    Explore a suite of essential utility functions designed to streamline your coding workflow. 
                                                    Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript,a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today.`} />
                {/* Add meta tags, links, etc. here if needed */}
            </Helmet>

            {/* Main Content */}
            <div className="w-full md:w-2/3 md:mx-4 flex-3 flex-col">
                <div className='flex justify-between'>
                    <h3 className='h3 inline font-medium text-lg mt-4'>{TitleText}</h3>
                    <select value={selectedKey} onChange={(e) => navigate(`/converter/${ConverterConstent[e.target.value].nevigtion}`)} className='border rounded-md h-10 mt-4 w-48'>
                        {Object.keys(ConverterConstent).map((e) => {
                            return <option value={e}>{e}</option>
                        })}
                    </select>
                </div>
                <div className="mx-auto md:h-max h-full flex flex-row ">

                    <div className="flex flex-1 flex-col  mr-4">
                        <div className='flex justify-between mb-5 mt-5'>
                            <button
                                className="bg-red-500 text-white px-4 py-2 rounded"
                                onClick={handleClear}
                            >
                                Clear
                            </button>
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                onClick={handleConvert}
                            >
                                Convert
                            </button>
                        </div>
                        <h3 className='h3 font-medium text-lg'>Input</h3>
                        <textarea
                            className="flex-1 p-2 border border-gray-300 rounded mb-4 resize-none"
                            rows={10}
                            placeholder="Enter JSON here"
                            value={jsonText}
                            onChange={(e) => setJsonText(e.target.value)}
                        />
                        <h3 className='h3 font-medium text-lg'>Output</h3>
                        {error ? <div className='text-red-500 mt-4'><p className='text-red-500 '>{`${error}`}</p></div> : <></>}
                        <div className='h-10 w-full rounded-t bg-slate-600 flex justify-center items-center text-center text-white hover:bg-slate-400  hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`${csharpText}`) }}>
                            <p>Copy to Clipboard</p>
                        </div>
                        <div className='w-full flex flex-col bd-black bg-slate-900 min-h-72'>
                            <div className='text-white bg-slate-800 p-2 w-16'><h3>Code</h3></div>
                            <code className="text-white bg-slate-800 p-2 block w-full min-h-52"><pre className='text-white bg-slate-800'>{csharpText}</pre></code>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvertisementComponent;
