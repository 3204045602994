import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AlphaPicker, ColorResult, HuePicker, SketchPicker } from 'react-color';
import { SketchPickerStylesProps } from 'react-color/lib/components/sketch/Sketch';
import { FiX, FiPlus } from 'react-icons/fi';
import { v4 as uuidv4 } from 'uuid';
import CircularSlider from '../utils/circleSlider';
import { Helmet } from 'react-helmet-async';
import { GoogleAddScript } from '../Constent/Uiconstent';

interface GradientStop {
    id: string;
    color: ColorResult;
    stop: number;
}
const customStyles: Partial<SketchPickerStylesProps> = {
    picker: {
        boxShadow: 'none',
        padding: '0',
        background: 'transparent',
        borderRadius: '10px',
    },
    saturation: {
        padding: '0',
        width: '100%',
        height: '150px',
    },
    hue: {
        display: 'none'
    },
    controls: {
        display: 'none',
    },
    color: {
        display: 'none',
    },
};

const GradientMaker: React.FC = () => {
    const [gradientStops, setGradientStops] = useState<GradientStop[]>([
        {
            id: uuidv4(), color: {
                "hsl": {
                    "h": 250.18867924528305,
                    "s": 0.5820590936144004,
                    "l": 0.5851088099999999,
                    "a": 1
                },
                "hex": "#6d58d3",
                "rgb": {
                    "r": 109,
                    "g": 88,
                    "b": 211,
                    "a": 1
                },
            }, stop: 0
        },
        {
            id: uuidv4(), color: {
                "hsl": {
                    "h": 240,
                    "s": 0.8021265092809515,
                    "l": 0.233058,
                    "a": 1
                },
                "hex": "#0c0c6b",
                "rgb": {
                    "r": 12,
                    "g": 12,
                    "b": 107,
                    "a": 1
                }
            },
            stop: 35
        },
        {
            id: uuidv4(), color: {
                "hsl": {
                    "h": 195.05882352941174,
                    "s": 0.9317859500297366,
                    "l": 0.5103648,
                    "a": 1
                },
                "hex": "#0ebcf6",
                "rgb": {
                    "r": 14,
                    "g": 188,
                    "b": 246,
                    "a": 1
                }
            }, stop: 100
        },
    ]);
    const [colorSelected, setColorSelected] = useState<ColorResult | null>()
    const [selectedStop, setSelectedStop] = useState<GradientStop | null>(null);
    const [gradientType, setGradientType] = useState<'linear' | 'radial'>('linear');
    const [angle, setAngle] = useState<number>(90);
    const tempRefs = useRef<Record<string, HTMLDivElement | null>>({});
    const parentRef = useRef<HTMLDivElement>(null);
    const [parentWidth, setParentWidth] = useState<number>(0);

    const draggingState = useRef<{ id: string | null; startX: number, initialStop: number }>({
        id: null,
        startX: 0,
        initialStop: 0
    });

    useEffect(() => {
        if (parentRef.current) {
            setParentWidth(parentRef.current.offsetWidth);
        }
        const handleResize = () => {
            if (parentRef.current) {
                setParentWidth(parentRef.current.offsetWidth);
            }
        };
        window.addEventListener('resize', handleResize);
        setColorSelected(gradientStops[0].color)
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const setTempRef = useCallback((id: string, node: HTMLDivElement | null) => {
        if (node) {
            tempRefs.current[id] = node;
        }
    }, []);

    const handleMouseDown = (e: React.MouseEvent, id: string) => {
        const stop = gradientStops.find((s) => s.id === id);
        if (stop) {
            draggingState.current = { id, startX: e.clientX, initialStop: stop.stop };
        }
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (e: MouseEvent) => {
        if (draggingState.current.id !== null && parentRef.current) {
            const deltaX = e.clientX - draggingState.current.startX;
            const newStop = Math.min(Math.max(draggingState.current.initialStop + (deltaX / parentWidth) * 100, 0), 100);

            setGradientStops((prevStops) =>
                prevStops.map((stop) =>
                    stop.id === draggingState.current!.id ? { ...stop, stop: newStop } : stop
                )
            );
        }
    };

    const handleMouseUp = () => {
        draggingState.current = { id: null, startX: 0, initialStop: 0 };
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleColorChange = (color: ColorResult) => {
        setColorSelected(color);
        if (selectedStop) {
            setGradientStops((prevStops) =>
                prevStops.map((stop) =>
                    stop.id === selectedStop.id ? { ...stop, color: color } : stop
                )
            );
        }
    };

    const handleStopChange = (id: string, stop: number) => {
        setGradientStops((prevStops) =>
            prevStops.map((s) => (s.id === id ? { ...s, stop } : s))
        );
    };

    const addGradientStop = () => {
        setGradientStops([
            ...gradientStops,
            {
                id: uuidv4(), color: {
                    "hsl": {
                        "h": 250.18867924528305,
                        "s": 0.5820590936144004,
                        "l": 0.5851088099999999,
                        "a": 1
                    },
                    "hex": "#6d58d3",
                    "rgb": {
                        "r": 109,
                        "g": 88,
                        "b": 211,
                        "a": 1
                    },
                }, stop: 50
            },
        ]);
    };

    const removeGradientStop = (id: string) => {
        setGradientStops(gradientStops.filter((stop) => stop.id !== id));
    };
    const handleChangeStop = (id: string, key: 'color' | 'stop', value: any) => {
        if (key === 'stop') {
            if (value < 0 || value > 100) {
                return;
            }
        } else if (key === 'color') {
            if (!isValidHexColor(value)) {
                return;
            }
        } else {
            return;
        }
        function hexToRGBA(hex: string) {
            let r = 0, g = 0, b = 0, a = 1;

            // Remove the hash at the start if it's there
            hex = hex.replace(/^#/, '');

            // Parse 3-digit hex
            if (hex.length === 3) {
                r = parseInt(hex[0] + hex[0], 16);
                g = parseInt(hex[1] + hex[1], 16);
                b = parseInt(hex[2] + hex[2], 16);
            } else if (hex.length === 6) {
                // Parse 6-digit hex
                r = parseInt(hex.slice(0, 2), 16);
                g = parseInt(hex.slice(2, 4), 16);
                b = parseInt(hex.slice(4, 6), 16);
            } else if (hex.length === 8) {
                // Parse 8-digit hex with alpha
                r = parseInt(hex.slice(0, 2), 16);
                g = parseInt(hex.slice(2, 4), 16);
                b = parseInt(hex.slice(4, 6), 16);
                a = parseInt(hex.slice(6, 8), 16) / 255;
            }

            return { r, g, b, a };
        }
        function rgbToHSL(r: number, g: number, b: number) {
            r /= 255;
            g /= 255;
            b /= 255;

            const max = Math.max(r, g, b), min = Math.min(r, g, b);
            let h = 0, s, l = (max + min) / 2;

            if (max === min) {
                h = s = 0; // achromatic
            } else {
                const d = max - min;
                s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                switch (max) {
                    case r: h = (g - b) / d + (g < b ? 6 : 0); break;
                    case g: h = (b - r) / d + 2; break;
                    case b: h = (r - g) / d + 4; break;
                }
                h /= 6;
            }

            return { h: Math.round(h * 360), s: Math.round(s * 100), l: Math.round(l * 100) };
        }

        const { r, g, b, a } = hexToRGBA(value);
        const { h, s, l } = rgbToHSL(r, g, b);
        setColorSelected({ hex: value, rgb: { r, g, b, a }, hsl: { h, s, l } })
        setGradientStops(
            gradientStops.map((stop) =>
                stop.id === id ? { ...stop, [key]: key === 'color' ? { hex: value, rgb: { r, g, b, a }, hsl: { h, s, l } } : value } : stop
            )
        );
    };

    function isValidHexColor(hex: string) {
        // Regular expression to match valid hex colors
        const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8}|[A-Fa-f0-9]{4})$/;
        return hexColorRegex.test(hex);
    }
    const handleDeleteStop = (id: string) => {
        setGradientStops(gradientStops.filter((stop) => stop.id !== id));
    };
    const gradientStyle = {
        background: `${gradientType}-gradient(${gradientType === 'linear' ? `${angle}deg,` : ''} ${gradientStops
            .map((stop) => `rgba(${stop.color.rgb.r},${stop.color.rgb.g},${stop.color.rgb.b},${stop.color.rgb.a}) ${stop.stop}%`)
            .join(', ')})`,
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen justify-center">
            <Helmet>
                <title>Gradient Maker - Coder Utils</title>
                <meta name="description" content="Gradient Maker ,colorfull background Maker,Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
                {/* Add meta tags, links, etc. here if needed */}
            </Helmet>
            <div className="w-full md:w-2/3 md:mx-4 flex-3 flex-col flex items-center relative">
                <div className="w-full h-52" style={gradientStyle}>

                </div>
                <div className='w-11/12  flex flex-col justify-center border  bg-white shadow-md absolute top-40 rounded'>
                    <div className='flex justify-center mt-3 mb-3 flex-col flex-wrap'>
                        <div ref={parentRef} className='w-full h-10 p-1 bg-white flex justify-center items-center border-blue-800 border rounded-md relative' >
                            <div className='h-8 flex-1' style={gradientStyle}>
                            </div>
                            {gradientStops.map((stop) => (
                                <div
                                    key={stop.id}
                                    ref={(node) => setTempRef(stop.id, node)}
                                    onMouseDown={(e) => handleMouseDown(e, stop.id)}
                                    style={{ left: `${(parentWidth * stop.stop) / 100 - 10}px` }}
                                    className='h-12 rounded-md -top-1 absolute w-3 z-1 bg-white flex justify-center items-center'>
                                    <div className='h-10 z-2 w-2 rounded-md' key={stop.id} style={{ backgroundColor: stop.color.hex }} />
                                </div>
                            ))}
                        </div>
                        <div className='pt-5 pb-5 mt-3 relative'>
                            <div className='w-full flex justify-center  items-center h-px bg-black'>
                                {gradientStops.map((stop) => (
                                    <div className='h-8 w-8 absolute z-1 bg-white flex items-center border-emerald-900 justify-center rounded border' style={{ left: `${(parentWidth * stop.stop) / 100 - 10}px` }}>
                                        <p> {Math.round(stop.stop)}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className='flex items-center border-b p-4 flex-wrap'>
                        <div className='UpperArea w-full flex-1 pr-10'>
                            <SketchPicker
                                className='w-full'
                                disableAlpha={true}
                                color={colorSelected?.hex}
                                presetColors={[]}
                                styles={{
                                    default: customStyles,
                                }}
                                onChange={(c) => { handleColorChange(c); }}
                                onChangeComplete={(c) => { handleColorChange(c); }}
                            />
                        </div>
                        <div className='flex-1 border-left-1 border-l border-gray-100 pr-10 pl-10 '>
                            <div>
                                <p>Color </p>
                            </div>
                            <div className='flex items-center jsutify-center  gap-2 '>
                                <div className='flex flex-2 flex-col jsutify-center'>
                                    <input
                                        type="text"
                                        value={colorSelected?.hex}
                                        onChange={(e) =>
                                            console.log(e.target.value)
                                        }
                                        className="w-20 p-2 border rounded"
                                    /><div className='w-20 text-center'><p>Hex</p></div>
                                </div>
                                <div className='flex flex-2 flex-col jsutify-center'>
                                    <input
                                        type="text"
                                        value={colorSelected?.rgb.r}
                                        readOnly
                                        onChange={(e) =>
                                            console.log(e.target.value)
                                        }
                                        className="w-14 p-2 border rounded"
                                    /><div className='w-14 text-center'><p>R</p></div>
                                </div><div className='flex flex-2 flex-col jsutify-center'>
                                    <input
                                        type="text"
                                        value={colorSelected?.rgb.g}
                                        readOnly
                                        onChange={(e) =>
                                            console.log(e.target.value)
                                        }
                                        className="w-14 p-2 border rounded"
                                    /><div className='w-14 text-center'><p>G</p></div>
                                </div>
                                <div className='flex flex-2 flex-col jsutify-center'>
                                    <input
                                        type="text"
                                        value={colorSelected?.rgb.b}
                                        readOnly
                                        onChange={(e) =>
                                            console.log(e.target.value)
                                        }
                                        className="w-14 p-2 border rounded"
                                    /><div className='w-14 text-center'><p>B</p></div>
                                </div>
                                <div className='flex flex-2 flex-col jsutify-center'>
                                    <input
                                        type="text"
                                        value={colorSelected?.rgb.a}
                                        readOnly
                                        onChange={(e) =>
                                            console.log(e.target.value)
                                        }
                                        className="w-14 p-2 border rounded"
                                    /><div className='w-14 text-center'><p>A</p></div>
                                </div>
                            </div>
                            <div className='mb-5 mt-5'>
                                <HuePicker
                                    color={colorSelected?.hex}
                                    onChange={(c) => handleColorChange(c)}
                                    onChangeComplete={(c) => { handleColorChange(c); }}></HuePicker>
                            </div>
                            <div className='mb-5'>
                                <AlphaPicker
                                    color={colorSelected?.rgb}
                                    onChange={(c) => handleColorChange(c)}
                                    onChangeComplete={(c) => { handleColorChange(c); }}></AlphaPicker>
                            </div>
                        </div>
                        <div className="flex flex-col border-l  border-gray-100  pr-10 pl-10">
                            {gradientStops.map((stop) => (
                                <div
                                    key={stop.id}
                                    onClick={(e) => { setSelectedStop(stop); setColorSelected(stop.color) }}
                                    style={{ backgroundColor: stop.id === selectedStop?.id ? '#efeef5' : 'white' }}
                                    className="flex items-center gap-3 p-2 border  bg-white shadow-md"
                                >
                                    {stop.id !== selectedStop?.id ? <div
                                        className="w-8 h-8 rounded"
                                        style={{ backgroundColor: stop.color.hex }}
                                    ></div> : <div className="w-10 h-10 rounded bg-white border  border-emerald-900 items-center justify-center flex">
                                        <div
                                            className="w-8 h-8 rounded"
                                            style={{ backgroundColor: stop.color.hex }}
                                        ></div></div>}
                                    <input
                                        type="text"
                                        value={stop.color.hex}
                                        onChange={(e) =>
                                            handleChangeStop(stop.id, 'color', e.target.value)
                                        }
                                        className="w-20 p-2 border rounded"
                                    />
                                    <input
                                        type="number"
                                        value={stop.stop}
                                        max={100}
                                        min={0}
                                        onChange={(e) =>
                                            handleChangeStop(stop.id, 'stop', Number(e.target.value))
                                        }
                                        className="w-16 p-2 border rounded"
                                    />
                                    <button
                                        onClick={() => handleDeleteStop(stop.id)}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <FiX />
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="w-20 flex flex-row pt-10 pb-10 pl-5">
                            <button
                                onClick={() => setGradientType('linear')}
                                className='p-2 text-center'
                                style={{ backgroundColor: gradientType === 'linear' ? '#3a1cc2' : '#fff', color: gradientType === 'linear' ? '#fff' : '#3a1cc2' }}
                            >
                                Linear
                            </button>
                            <button
                                className='p-2 text-center'
                                onClick={() => setGradientType('radial')}
                                style={{ backgroundColor: gradientType === 'radial' ? '#3a1cc2' : '#fff', color: gradientType === 'radial' ? '#fff' : '#3a1cc2' }}
                            >
                                Radial
                            </button>
                            <div className='border-l'>
                                {gradientType === 'linear' && (
                                    <div className="">
                                        <CircularSlider
                                            CircleSliderClass='w-10 h-10'
                                            value={angle}
                                            onChange={(e: number) => setAngle(e)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='w-full flex flex-col h-32 bd-black bg-slate-900'>
                            <div className='text-white bg-slate-800 p-2 w-16'><h3>Code</h3></div>
                            <code className="bg-slate-800 p-2 h-32  block w-full"><pre>{`background: ${gradientStyle.background};`}</pre></code>
                        </div>
                        <div className='h-10 w-full rounded-b bg-slate-600 flex justify-center items-center text-center text-white hover:bg-slate-400  hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`background: ${gradientStyle.background};`) }}>
                            <p>Copy to Clipboard</p>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default GradientMaker;
