import React, { useState } from 'react';
import UglifyJS, { MinifyOptions as UglifyOptions } from 'uglify-js';
import CleanCSS, { Options as CleanCSSOptions } from 'clean-css';
import { minify as htmlMinify, Options as HtmlMinifierOptions } from 'html-minifier';

const CodeMinifier: React.FC = () => {
  const [code, setCode] = useState('');
  const [minifiedCode, setMinifiedCode] = useState('');
  const [language, setLanguage] = useState('javascript');

  // const handleMinify = () => {
  //   let result = '';
  //   try {
  //     if (language === 'javascript') {
  //       const minified = UglifyJS.minify(code) as UglifyJS.MinifyOutput;
  //       result = minified.code || 'Error in minification';
  //     } else if (language === 'css') {
  //       const minified = new CleanCSS().minify(code) as CleanCSS.Output;
  //       result = minified.styles || 'Error in minification';
  //     } else if (language === 'html') {
  //       const options: HtmlMinifierOptions = {
  //         collapseWhitespace: true,
  //         removeComments: true,
  //         minifyCSS: true,
  //         minifyJS: true,
  //       };
  //       result = htmlMinify(code, options);
  //     }
  //     setMinifiedCode(result);
  //   } catch (error) {
  //     setMinifiedCode('Error in minification');
  //   }
  // };

  // return (
  //   <div className="max-w-xl mx-auto p-4">
  //     <h1 className="text-2xl font-bold mb-4">Code Minifier</h1>
  //     <div className="mb-4">
  //       <label className="block text-sm font-medium mb-1" htmlFor="language">
  //         Select Language
  //       </label>
  //       <select
  //         id="language"
  //         value={language}
  //         onChange={(e) => setLanguage(e.target.value)}
  //         className="w-full p-2 border border-gray-300 rounded"
  //       >
  //         <option value="javascript">JavaScript</option>
  //         <option value="css">CSS</option>
  //         <option value="html">HTML</option>
  //       </select>
  //     </div>
  //     <div className="mb-4">
  //       <label className="block text-sm font-medium mb-1" htmlFor="code">
  //         Code to Minify
  //       </label>
  //       <textarea
  //         id="code"
  //         value={code}
  //         onChange={(e) => setCode(e.target.value)}
  //         className="w-full p-2 border border-gray-300 rounded"
  //         rows={10}
  //         placeholder="Enter your code here"
  //       />
  //     </div>
  //     <button
  //       onClick={handleMinify}
  //       className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
  //     >
  //       Minify Code
  //     </button>
  //     <div>
  //       <h2 className="text-xl font-bold mb-2">Minified Code</h2>
  //       <textarea
  //         readOnly
  //         value={minifiedCode}
  //         className="w-full p-2 border border-gray-300 rounded"
  //         rows={10}
  //       />
  //     </div>
  //   </div>
  // );
  return (<div>Hello World</div>)
};

export default CodeMinifier;
