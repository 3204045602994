import React, { useEffect, useRef, useState } from 'react';
import Papa from "papaparse";
import Tabs, { Tab } from '../utils/Tabs';
import CollapsiblePanel from '../utils/CollapsiblePanel';
import CsvInputOptions from '../utils/CsvInputOptions';
import CsvOutputOptions from '../utils/CsvOutputOptions';
import { CsvToSqlConvertProvider, generateSQL, useCsvToSqlConvertContext } from '../utils/csvToSqlUtils';
import { v4 as uuidv4 } from 'uuid';
import { csvTableStructure, determineDataType, generateSql, parseCsv } from '../utils/csvToSqlUtils';
import diacritics from 'diacritics';
import { Helmet } from 'react-helmet-async';
// import { useTable } from 'react-table';

const SQLDialects = ['mysql', 'postgresql', 'sqlserver'];
const encodingType = [{ name: '', description: "-Default - " },
{ name: "ISO-8859-1", description: "ISO-8859-1 (Latin No. 1)" },
{ name: "ISO-8859-2", description: "ISO-8859-2 (Latin No. 2)" },
{ name: "ISO-8859-3", description: "ISO-8859-3 (Latin No. 3)" },
{ name: "ISO-8859-4", description: "ISO-8859-4 (Latin No. 4)" },
{ name: "ISO-8859-5", description: "ISO-8859-5 (Latin/Cyrillic)" },
{ name: "ISO-8859-6", description: "ISO-8859-6 (Latin/Arabic)" },
{ name: "ISO-8859-7", description: "ISO-8859-7 (Latin/Greek)" },
{ name: "ISO-8859-8", description: "ISO-8859-8 (Latin/Hebrew)" },
{ name: "ISO-8859-9", description: "ISO-8859-9 (Latin No. 5)" },
{ name: "ISO-8859-13", description: "ISO-8859-13 (Latin No. 7)" },
{ name: "ISO-8859-15", description: "ISO-8859-15 (Latin No. 9)" },
{ name: "macintosh", description: "Mac OS Roman" },
{ name: "UTF-8", description: "UTF-8" },
{ name: "UTF-16", description: "UTF-16" },
{ name: "UTF-16BE", description: "UTF-16 (Big-Endian)" },
{ name: "UTF-16LE", description: "UTF-16 (Little-Endian)" },
{ name: "UTF-32", description: "UTF-32" },
{ name: "UTF-32BE", description: "UTF-32 (Big-Endian)" },
{ name: "UTF-32LE", description: "UTF-32 (Little-Endian)" },
{ name: "windows-1250", description: "windows-1250 (Win East European)" },
{ name: "windows-1251", description: "windows-1251 (WinCyrillic)" },
{ name: "windows-1252", description: "windows-1252 (WinLatin-1)" },
{ name: "windows-1253", description: "windows-1253 (WinGreek)" },
{ name: "windows-1254", description: "windows-1254 (Win Turkish)" },
{ name: "windows-1255", description: "windows-1255 (Win Hebrew)" },
{ name: "windows-1256", description: "windows-1256 (Win Arabic)" },
{ name: "windows-1258", description: "windows-1257 (Win Vietnamese)windows-1257 windows-1257 (Win Baltic)" }]

const CsvToSqlConverter: React.FC = () => {

  const [csvData, setCsvData] = useState<any>(null);
  const [resultData, setResultData] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [encoding, setEncoding] = useState<string>('');
  const [fileContent, setFileContent] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const { updateCsvTableStructureOptions, options, inputOption } = useCsvToSqlConvertContext();


  const fetchCsvFile = async (url: string) => {
    try {
      const response = await fetch(url);
      const text = await response.text();
      console.log(text);
      setFileContent(text);
    } catch (ex) {
      console.log(ex);
      setError('Unable to fetch the CSV file');
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (encoding) {
            const textDecoder = new TextDecoder(encoding);
            const decodedContent = textDecoder.decode(event.target?.result as ArrayBuffer);
            setFileContent(decodedContent as string);
          } else
            setFileContent(event.target?.result as string);
        };
        reader.readAsText(file);
      }
    } catch (ex) {
      console.log(ex);
      setError('unable to convert the CSV');
    }
  };

  const convertTextToCSV = (text: string) => {
    let option: Papa.ParseConfig<any> = {
      header: inputOption?.firstRowAsColumnNames,
      skipEmptyLines: true,

    }
    if (inputOption?.fieldSeparator != 'Auto Detect') {
      if (inputOption?.fieldSeparator == "Space")
        option['delimiter'] = ' '
      else if (inputOption?.fieldSeparator == "Tab")
        option['delimiter'] = ' '
      else if (inputOption?.fieldSeparator == "Caret-^")
        option['delimiter'] = '^'
      else
        option['delimiter'] = inputOption?.fieldSeparator
    } else {
      option.delimitersToGuess = [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
    }
    if (inputOption?.csvQuotingCharacter) {
      option.quoteChar = "'"
    }
    if (inputOption?.csvContainsBackslashEscaping) {
      option.escapeChar = '\\'
    }

    let normalizedText = inputOption?.replaceAccents ? diacritics.remove(text) : text;
    const csv = Papa.parse(normalizedText, option);
    const parsedData = csv?.data;
    const rows = Object.keys(parsedData[0] as any);
    const res = rows.reduce((acc: csvTableStructure[], e, i) => {
      let columnValues = parsedData.map((ob: any) => ob[e]);
      return [...acc, { id: uuidv4(), head2: e, head: e, key: i === 0, sqlType: determineDataType(columnValues), upper: false, lower: false, emptyValueNull: true, include: true, isRequired: !columnValues.some((ob: string) => !ob || ob === '') }];
    }, []);
    updateCsvTableStructureOptions(res);
    setCsvData(csv);
  }

  useEffect(() => {
    if (fileContent) {
      convertTextToCSV(fileContent);
    }
  }, [fileContent, inputOption]);


  const handleGenerateSql = () => {
    if (options && options.csvTableStructureOption && inputOption)
      setResultData(generateSQL(options, inputOption, csvData?.data))
  }

  return (
    <div className='flex flex-col md:flex-row min-h-screen justify-center'>
      <Helmet>
        <title>Csv to Sql Converter - Coder Utils</title>
        <meta name="description" content="Csv to Sql Converter ,convert CSV data to an sql query based on different Param
                                              ,Explore a suite of essential utility functions designed to streamline your coding workflow. Our tools include JSON converters for C#, VB, Python, Ruby, and TypeScript, a versatile UUID generator, an intuitive HTML prettifier, a dynamic gradient maker, and a robust regex tester. Simplify everyday tasks with powerful, easy-to-use utilities tailored to meet developers' needs. Dive in and elevate your coding experience with Coder Utils today." />
        {/* Add meta tags, links, etc. here if needed */}
      </Helmet>
      <div className="w-full md:w-2/3 md:mx-4 flex-3 flex-col flex items-center relative">
        <section className='w-full mt-4' title='Step 1 : Select your input' about='This section is first step where you enter your data to convert into sql '>
          <h2 className='h2 font-semibold text-xl'>Step 1 : Select your input</h2>
          <Tabs>
            <Tab label="Enter Data">
              <div className='p-2'>
                <textarea placeholder='Enter your csv data here....' onChange={(e) => convertTextToCSV(e.target.value)} className='w-full min-h-72 p-2 border rounded mb-4 resize-none'></textarea>
              </div>
            </Tab>
            <Tab label="Choose File">
              <div className='p-2 flex items-center gap-4'>
                <h4 className='h4'>Choose File:</h4>
                <div className='border rounded flex gap-4 items-center mr-2'>
                  <input type="file" onChange={handleFileUpload} className="bg-blue-500 text-white py-2 px-4 rounded" />
                  {/* <p className='mr-2'>Filename.csv</p> */}
                </div>
                <h4 className='h4'>encoding: </h4>
                <select value={encoding} onChange={(e) => setEncoding(e.target.value)} className='w-72 border rounded'>
                  {encodingType.map((op) => (<option value={op.name}>{op.description}</option>))}
                </select>
              </div>
            </Tab>
            <Tab label="Enter Url">
              <div className='p-2 flex items-center gap-4'>
                <h4 className='h4'>Enter Url:</h4>
                <div className='border rounded flex gap-4 items-center'>
                  <input value={url} onChange={(e) => setUrl(e.target.value)} type='text' className='border w-72 ml-4 mr-4' />
                  <button onClick={e => fetchCsvFile(url)} className='bg-blue-500 text-white py-2 px-4 rounded'>Get Data</button>
                </div>
              </div>
            </Tab>
          </Tabs>
        </section>
        <section className='w-full mt-4' title='Step 2: Choose input options'>
          <CollapsiblePanel title='Step 2: Choose input options'>
            <div className='p-2 flex'>
              <CsvInputOptions></CsvInputOptions>
            </div>
          </CollapsiblePanel>
        </section>
        <section className='w-full mt-4' title='Step 3: Choose output options'>
          <CollapsiblePanel title='Step 3: Choose output options'>
            <div className='p-2 flex'>
              <CsvOutputOptions ></CsvOutputOptions>
            </div>
          </CollapsiblePanel>
        </section>
        <section className='w-full mt-4' title='Step 3: Choose output options'>
          <button disabled={!options || !csvData} onClick={handleGenerateSql} className="bg-blue-500 text-white px-4 py-2 rounded-md">Generate SQL</button>
          <div className='w-full mt-4 flex flex-col bd-black bg-slate-900 min-h-72 max-h-96  rounded-md'>
            <div className='h-10 py-4 w-full rounded-t bg-slate-600 flex justify-center items-center text-center text-white hover:bg-slate-400  hover:cursor-pointer' onClick={() => { navigator.clipboard.writeText(`${resultData}`) }}>
              <p>Copy to Clipboard</p>
            </div>
            <div className='text-white bg-slate-800 p-2 w-16'><h3>Query</h3></div>
            <code className="text-white bg-slate-800 p-2 block w-full min-h-52 overflow-scroll"><pre className='text-white bg-slate-800'>{resultData}</pre></code>
          </div>
        </section>
      </div>
    </div>
  )
};

export default CsvToSqlConverter;
