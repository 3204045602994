import csv from 'csvtojson';

export function jsonToTypeScriptClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generateTypeScriptClass(jsonObj: any, objName: string): string {
    let classString = `interface ${objName} {\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getTypeScriptType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getTypeScriptType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
            classString += `    ${capitalizeFirstLetter(key)}: ${type.replace('interface ', '')}[];\n`;
            classMap[nestedClassName] = generateTypeScriptClass(value[0], nestedClassName);
          } else {
            classString += `    ${capitalizeFirstLetter(key)}: ${elementType}[];\n`;
          }
        } else if (typeof value === 'object') {
          classString += `    ${capitalizeFirstLetter(key)}: ${type.replace('interface ', '')};\n`;
          const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generateTypeScriptClass(value, nestedClassName);
        } else {
          classString += `    ${capitalizeFirstLetter(key)}: ${type};\n`;
        }
      }
    }

    classString += `}\n`;
    return classString;
  }

  function getTypeScriptType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'string';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'number';
      } else if (value.toString().indexOf('.') !== -1 && value.toString().split('.')[1].length > 7) {
        return 'number'; // TypeScript's 'number' type for large decimals
      } else {
        return 'number'; // TypeScript's 'number' type for floats
      }
    } else if (typeof value === 'boolean') {
      return 'boolean';
    } else if (Array.isArray(value)) {
      const elementType = getTypeScriptType(value[0], typeName);
      return `${elementType}[]`;
    } else if (value instanceof Date) {
      return 'Date';
    } else if (typeof value === 'object') {
      return `interface ${typeName}`;
    } else {
      return 'any';
    }
  }

  const classString = generateTypeScriptClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += classMap[key];
  }

  return result + classString;
}

export function jsonToCSharpClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generateClass(jsonObj: any, objName: string): string {
    let classString = `public class ${objName} {\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getCSharpType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getCSharpType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
            classString += `    public ${type.replace('class ', '')} ${capitalizeFirstLetter(key)} { get; set; }\n`;
            classMap[nestedClassName] = generateClass(value[0], nestedClassName);
          } else {
            classString += `    public ${elementType}[] ${capitalizeFirstLetter(key)} { get; set; }\n`;
          }
        } else if (typeof value === 'object') {
          classString += `    public ${type.replace('class ', '')} ${capitalizeFirstLetter(key)} { get; set; }\n`;
          const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generateClass(value, nestedClassName);
        } else {
          classString += `    public ${type} ${capitalizeFirstLetter(key)} { get; set; }\n`;
        }
      }
    }

    classString += `}\n`;
    return classString;
  }

  function getCSharpType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'string';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'int';
      } else if (value.toString().indexOf('.') !== -1 && value.toString().split('.')[1].length > 7) {
        return 'decimal';
      } else {
        return 'float';
      }
    } else if (typeof value === 'boolean') {
      return 'bool';
    } else if (value instanceof Date) {
      return 'DateTime';
    } else if (Array.isArray(value)) {
      const elementType = getCSharpType(value[0], typeName);
      return `${elementType}[]`;
    } else if (typeof value === 'object') {
      return `class ${typeName}`;
    } else {
      return 'object';
    }
  }

  const classString = generateClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += classMap[key];
  }

  return result + classString;
}

function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function jsonToVBClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generateVBClass(jsonObj: any, objName: string): string {
    let classString = `Public Class ${objName}\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getVBType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getVBType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
            classString += `    Public Property ${capitalizeFirstLetter(key)} As ${type.replace('class ', '')}()\n`;
            classMap[nestedClassName] = generateVBClass(value[0], nestedClassName);
          } else {
            classString += `    Public Property ${capitalizeFirstLetter(key)}() As ${elementType}()\n`;
          }
        } else if (typeof value === 'object') {
          classString += `    Public Property ${capitalizeFirstLetter(key)} As ${type.replace('class ', '')}\n`;
          const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generateVBClass(value, nestedClassName);
        } else {
          classString += `    Public Property ${capitalizeFirstLetter(key)} As ${type}\n`;
        }
      }
    }

    classString += `End Class\n`;
    return classString;
  }

  function getVBType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'String';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'Integer';
      } else if (value.toString().indexOf('.') !== -1 && value.toString().split('.')[1].length > 7) {
        return 'Decimal';
      } else {
        return 'Single';
      }
    } else if (typeof value === 'boolean') {
      return 'Boolean';
    } else if (Array.isArray(value)) {
      const elementType = getVBType(value[0], typeName);
      return `${elementType}()`;
    } else if (value instanceof Date) {
      return 'DateTime';
    } else if (typeof value === 'object') {
      return typeName; // VB.NET does not require 'class' keyword for types
    } else {
      return 'Object';
    }
  }

  const classString = generateVBClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += classMap[key];
  }

  return result + classString;
}

export function jsonToRubyClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generateRubyClass(jsonObj: any, objName: string): string {
    let classString = `class ${objName}\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getRubyType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getRubyType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
            classString += `  attr_accessor :${capitalizeFirstLetter(key)}\n`;
            classMap[nestedClassName] = generateRubyClass(value[0], nestedClassName);
          } else {
            classString += `  attr_accessor :${capitalizeFirstLetter(key)}\n`;
          }
        } else if (typeof value === 'object') {
          classString += `  attr_accessor :${capitalizeFirstLetter(key)}\n`;
          const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generateRubyClass(value, nestedClassName);
        } else {
          classString += `  attr_accessor :${capitalizeFirstLetter(key)}\n`;
        }
      }
    }

    classString += `end\n`;
    return classString;
  }

  function getRubyType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'String';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'Integer';
      } else {
        return 'Float';
      }
    } else if (typeof value === 'boolean') {
      return 'Boolean';
    } else if (Array.isArray(value)) {
      const elementType = getRubyType(value[0], typeName);
      return elementType + '[]';
    } else if (value instanceof Date) {
      return 'DateTime';
    } else if (typeof value === 'object') {
      return typeName;
    } else {
      return 'Object';
    }
  }

  const classString = generateRubyClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += `\n${classMap[key]}`;
  }

  return result + classString;
}

export function jsonToPythonClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generatePythonClass(jsonObj: any, objName: string): string {
    let classString = `class ${objName}:\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getPythonType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getPythonType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
            classString += `    ${capitalizeFirstLetter(key)}: List[${type.replace('class ', '')}]\n`;
            classMap[nestedClassName] = generatePythonClass(value[0], nestedClassName);
          } else {
            classString += `    ${capitalizeFirstLetter(key)}: List[${elementType}]\n`;
          }
        } else if (typeof value === 'object') {
          classString += `    ${capitalizeFirstLetter(key)}: ${type.replace('class ', '')}\n`;
          const nestedClassName = `${objName}_${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generatePythonClass(value, nestedClassName);
        } else {
          classString += `    ${capitalizeFirstLetter(key)}: ${type}\n`;
        }
      }
    }

    return classString;
  }

  function getPythonType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'str';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'int';
      } else {
        return 'float';
      }
    } else if (typeof value === 'boolean') {
      return 'bool';
    } else if (Array.isArray(value)) {
      const elementType = getPythonType(value[0], typeName);
      return `List[${elementType}]`;
    } else if (value instanceof Date) {
      return 'datetime';
    } else if (typeof value === 'object') {
      return `class ${typeName}`;
    } else {
      return 'Any';
    }
  }

  const classString = generatePythonClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += `\n${classMap[key]}`;
  }

  return result + classString;
}
export function jsonToFlutterClass(jsonObject: Record<string, any>, className: string): string {
  const classMap: Record<string, string> = {};

  function generateFlutterClass(jsonObj: any, objName: string): string {
    let classString = `class ${capitalizeFirstLetter(objName)} {\n`;

    for (const key in jsonObj) {
      if (jsonObj.hasOwnProperty(key)) {
        const value = jsonObj[key];
        const type = getFlutterType(value, `${objName}_${capitalizeFirstLetter(key)}`);

        if (Array.isArray(value)) {
          const elementType = getFlutterType(value[0], `${objName}_${capitalizeFirstLetter(key)}`);
          if (typeof value[0] === 'object') {
            const nestedClassName = `${capitalizeFirstLetter(objName)}${capitalizeFirstLetter(key)}`;
            classString += `  List<${type}> ${key};\n`;
            classMap[nestedClassName] = generateFlutterClass(value[0], nestedClassName);
          } else {
            classString += `  List<${elementType}> ${key};\n`;
          }
        } else if (typeof value === 'object') {
          classString += `  ${type} ${key};\n`;
          const nestedClassName = `${capitalizeFirstLetter(objName)}${capitalizeFirstLetter(key)}`;
          classMap[nestedClassName] = generateFlutterClass(value, nestedClassName);
        } else {
          classString += `  ${type} ${key};\n`;
        }
      }
    }

    classString += `}\n`;
    return classString;
  }

  function getFlutterType(value: any, typeName: string): string {
    if (typeof value === 'string') {
      return 'String';
    } else if (typeof value === 'number') {
      if (Number.isInteger(value)) {
        return 'int';
      } else {
        return 'double';
      }
    } else if (typeof value === 'boolean') {
      return 'bool';
    } else if (Array.isArray(value)) {
      const elementType = getFlutterType(value[0], typeName);
      return `List<${elementType}>`;
    } else if (value instanceof Date) {
      return 'DateTime';
    } else if (typeof value === 'object') {
      return capitalizeFirstLetter(typeName);
    } else {
      return 'dynamic';
    }
  }

  const classString = generateFlutterClass(jsonObject, className);

  let result = '';
  for (const key in classMap) {
    result += `\n${classMap[key]}`;
  }

  return result + classString;
}